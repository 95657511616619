/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import FooterSimple from '../../layout/FooterSimple/FooterSimple';
import './AboutUs.css';

export default function AboutUs() {
  return (
    <div className='contAboutUs'>
      <div className='compHeadM'></div>
      <section className='AUSectOne'>
        <div className='leftAUSecOne'>
          <p className='txtTitileSectOneAU'>NUESTRO<br></br>EQUIPO<br></br><span className='yellowText'>DE EXPERTOS</span></p>
        </div>
        <div className='rightAUSecOne'>
          <p className='txtTitileSectOneAU2'>"Acertar con la persona <br></br>
            es vital. Debe ser un <br></br>
            experto, el mejor, <br></br>
            si es posible..." <br></br>
            Josep Maria <br></br>
            Minguella </p>
        </div>
      </section>
      <section className='AUSectTwo2 twoProfilesRowMob'>
        <div className='contExpert2'>
          <div className='contImgAU imgAU1'></div>
          <div className='contTxtDescriptionExpert'>
            <p className='txtDescriptionExpert'>
              CARLOS SALAS <br></br>
              CEO <br></br><br></br>
              Fundador de diversos grupos de gran éxito como Vanguard Group y recientemente Rhythm & Harmony, 
              Carlos se graduó en Ciencias Políticas y Sociales y tuvo un extenso entrenamiento en marketing y desarrollo de negocios. 
              Ha participado en el desarrollo de numerosos proyectos de inversión en diversos territorios como España, 
              México, el Caribe y Marruecos. <br></br> <br></br>
              
            </p>
          </div>
        </div>
        <div className='contExpert2'>
          <div className='contImgAU imgAU2'></div>
          <div className='contTxtDescriptionExpert2 '>
            <p className='txtDescriptionExpert borderBlack22'>
              JUNIOR MINGUELLA <br></br>
              CHIEF SPORTS OFFICER <br></br><br></br> <br></br> <br></br> <br></br><br></br> <br></br> <br></br> <br></br> <br></br>
              
            </p>
          </div>
        </div>
      </section>
      <section className='AUSectTwo2 twoProfilesRowMob'>
        <div className='contExpert2'>
          <div className='contImgAU imgAU3'></div>
          <div className='contTxtDescriptionExpert'>
            <p className='txtDescriptionExpert'>
              JOSEP MARIA MIGUELLA<br></br>
              HONORARY SPORT PRESIDENT <br></br><br></br>
              Josep Maria Minguella ha sido una de las personas más influyentes del fútbol mundial de los últimos 50 años. 
              El coach y agente que trajo a Maradona, Stoichkov, Romário y Messi al Barça conoce las diferentes situaciones del mundo del fútbol, 
              el negocio y tiene excepcionales habilidades de expresión. A sus 80 años, tiene una memoria prodigiosa y una gran 
              capacidad de análisis. <br></br> <br></br> 
              
            </p>
          </div>
        </div>
        <div className='contExpert2'>
          <div className='contImgAU imgAU4'></div>
          <div className='contTxtDescriptionExpert '>
            <p className='txtDescriptionExpert borderBlack22'>
              JOSÉ ANTONIO PEDRAZA<br></br>
              CHIEF INFORMATION OFFICER <br></br><br></br>
              Con más de 25 años de experiencia en el sector tecnológico, ha sido CEO de diversas compañías de software y telecomunicaciones, 
              y fundador de start-ups disruptivas como Proximo Technologies, una empresa de servicios ganadora en el programa Shark Tank México. 
              Cuenta con un MBA por parte de la Universidad de Texas, sin embargo, sus intereses abarcan desde la arquitectura tecnológica, 
              las fintechs, los nuevos modelos de negocio, hasta el Stand-Up Commedy donde llegó a presentarse profesionalmente. <br></br> <br></br>
              
            </p>
          </div>
        </div>
      </section>
      <section className='AUSectTwo2 twoProfilesRowMob'>
        <div className='contExpert2'>
          <div className='contImgAU imgAU5'></div>
          <div className='contTxtDescriptionExpert2 '>
            <p className='txtDescriptionExpert borderBlack'>
              JOAQUÍN NUÑEZ<br></br>
              FINANTIAL INSTITUCIONAL RELATIONS <br></br><br></br>
              Es Licenciado en Ciencias Empresariales por la Universidad de Barcelona y PDD por el IESE. 
              Ha sido Profesor en materias de micro y macroeconomía, así como Planificación Financiera. 
              Desde finales de los 70's ha tenido una exitosa carrera directiva en el sector bancario siendo director de oficinas regionales, 
              director de banca privada y consejero en diversas sociedades de inversión manejando un volumen 
              que supera los 40,000 millones de euros. Forma parte del Círculo de Economía de Barcelona y de diversas 
              asociaciones financieras. <br></br> <br></br>
              
            </p>
          </div>
        </div>
        <div className='contExpert2'>
          <div className='contImgAU imgAU6'></div>
          <div className='contTxtDescriptionExpert2 '>
            <p className='txtDescriptionExpert borderBlack22'>
              PEDRO PUEYO<br></br>
              COMPLIANCE DIRECTOR<br></br><br></br>
              Más de 22 años de carrera en el sector de las finanzas y la gestión de activos, trabajando predominantemente en entornos 
              de UCITS (sobre todo fondos de renta variable y renta fija) y AIFM (fondos inmobiliarios, de capital privado y de cobertura), 
              con experiencia relevante en una amplia variedad de campos, estrategias de inversión, clases de activos e instrumentos financieros.
              Titular de la certificación CFA y miembro del consejo de administración con experiencia en varios fondos 
              regulados y empresas operativas. <br></br> <br></br>
              
            </p>
          </div>
        </div>
      </section>
      <section className='AUSectTwo2 twoProfilesRowMob'>
        <div className='contExpert2'>
          <div className='contImgAU imgAU7'></div>
          <div className='contTxtDescriptionExpert'>
            <p className='txtDescriptionExpert'>
              JAVIER MORATO<br></br>
              FINANTIAL RISK DIRECTOR <br></br><br></br>
              Es doctor en economía por la Universidad de Sevilla. Cuenta con 20 años de experiencia en el sector bancario 
              con especialidad en análisis de riesgo y modelos financieros. Ha contribuido desde el Control y Proyectos de 
              bienes raíces para Banco Sabadell, llevando áreas de riesgo crediticio en Andbank hasta la realización de análisis 
              de riesgo para Banesto. <br></br> <br></br>
              
            </p>
          </div>
        </div>
      </section>
      <section className='AUSectTwo'>
        <div className='contExpert'>
          <div className='contImgAU imgAU1'></div>
          <div className='contTxtDescriptionExpert'>
            <p className='txtDescriptionExpert'>
              CARLOS SALAS <br></br>
              CEO <br></br><br></br>
              Fundador de diversos grupos de gran éxito como Vanguard Group y recientemente Rhythm & Harmony, 
              Carlos se graduó en Ciencias Políticas y Sociales y tuvo un extenso entrenamiento en marketing y desarrollo de negocios. 
              Ha participado en el desarrollo de numerosos proyectos de inversión en diversos territorios como España, 
              México, el Caribe y Marruecos. <br></br> <br></br>
              
            </p>
          </div>
        </div>
        <div className='contExpert'>
          <div className='contImgAU imgAU2'></div>
          <div className='contTxtDescriptionExpert2 '>
            <p className='txtDescriptionExpert borderBlack'>
              JUNIOR MINGUELLA <br></br>
              CHIEF SPORTS OFFICER <br></br><br></br> <br></br> <br></br> <br></br><br></br> <br></br> <br></br> <br></br> <br></br>
              
            </p>
          </div>
        </div>
        <div className='contExpert'>
          <div className='contImgAU imgAU3'></div>
          <div className='contTxtDescriptionExpert'>
            <p className='txtDescriptionExpert'>
              JOSEP MARIA MIGUELLA<br></br>
              HONORARY SPORT PRESIDENT <br></br><br></br>
              Josep Maria Minguella ha sido una de las personas más influyentes del fútbol mundial de los últimos 50 años. 
              El coach y agente que trajo a Maradona, Stoichkov, Romário y Messi al Barça conoce las diferentes situaciones del mundo del fútbol, 
              el negocio y tiene excepcionales habilidades de expresión. A sus 80 años, tiene una memoria prodigiosa y una gran 
              capacidad de análisis. <br></br> <br></br> 
              
            </p>
          </div>
        </div>
      </section>
      <section className='AUSectTwo'>
        <div className='contExpert'>
          <div className='contImgAU imgAU4'></div>
          <div className='contTxtDescriptionExpert'>
            <p className='txtDescriptionExpert'>
              JOSÉ ANTONIO PEDRAZA<br></br>
              CHIEF INFORMATION OFFICER <br></br><br></br>
              Con más de 25 años de experiencia en el sector tecnológico, ha sido CEO de diversas compañías de software y telecomunicaciones, 
              y fundador de start-ups disruptivas como Proximo Technologies, una empresa de servicios ganadora en el programa Shark Tank México. 
              Cuenta con un MBA por parte de la Universidad de Texas, sin embargo, sus intereses abarcan desde la arquitectura tecnológica, 
              las fintechs, los nuevos modelos de negocio, hasta el Stand-Up Commedy donde llegó a presentarse profesionalmente. <br></br> <br></br>
              
            </p>
          </div>
        </div>
        <div className='contExpert'>
          <div className='contImgAU imgAU5'></div>
          <div className='contTxtDescriptionExpert2 '>
            <p className='txtDescriptionExpert borderBlack'>
              JOAQUÍN NUÑEZ<br></br>
              FINANTIAL INSTITUCIONAL RELATIONS <br></br><br></br>
              Es Licenciado en Ciencias Empresariales por la Universidad de Barcelona y PDD por el IESE. 
              Ha sido Profesor en materias de micro y macroeconomía, así como Planificación Financiera. 
              Desde finales de los 70's ha tenido una exitosa carrera directiva en el sector bancario siendo director de oficinas regionales, 
              director de banca privada y consejero en diversas sociedades de inversión manejando un volumen 
              que supera los 40,000 millones de euros. Forma parte del Círculo de Economía de Barcelona y de diversas 
              asociaciones financieras. <br></br> <br></br>
              
            </p>
          </div>
        </div>
        <div className='contExpert'>
          <div className='contImgAU imgAU6'></div>
          <div className='contTxtDescriptionExpert2 '>
            <p className='txtDescriptionExpert '>
              PEDRO PUEYO<br></br>
              COMPLIANCE DIRECTOR<br></br><br></br>
              Más de 22 años de carrera en el sector de las finanzas y la gestión de activos, trabajando predominantemente en entornos 
              de UCITS (sobre todo fondos de renta variable y renta fija) y AIFM (fondos inmobiliarios, de capital privado y de cobertura), 
              con experiencia relevante en una amplia variedad de campos, estrategias de inversión, clases de activos e instrumentos financieros.
              Titular de la certificación CFA y miembro del consejo de administración con experiencia en varios fondos 
              regulados y empresas operativas. <br></br> <br></br>
              
            </p>
          </div>
        </div>
      </section>
      <section className='AUSectTwo'>
        <div className='contExpert'>
          <div className='contImgAU imgAU7'></div>
          <div className='contTxtDescriptionExpert'>
            <p className='txtDescriptionExpert'>
              JAVIER MORATO<br></br>
              FINANTIAL RISK DIRECTOR <br></br><br></br>
              Es doctor en economía por la Universidad de Sevilla. Cuenta con 20 años de experiencia en el sector bancario 
              con especialidad en análisis de riesgo y modelos financieros. Ha contribuido desde el Control y Proyectos de 
              bienes raíces para Banco Sabadell, llevando áreas de riesgo crediticio en Andbank hasta la realización de análisis 
              de riesgo para Banesto. <br></br> <br></br>
              
            </p>
          </div>
        </div>
      </section>
      <section className='homeSectEight'>
          <p className='txtTitleSectEight'>MIRA AQUÍ LAS RESEÑAS DE PRENSA</p>
          <br></br>
          <div className='conticonsReviews'>
            <div className='SectEightL'>
              <a target="_blank" rel="noreferrer" href='https://www.adnkronos.com/tokenchampions-lancia-il-primo-fondo-di-investimento-creato-per-acquisire-e-gestire-i-diritti-di-immagine-dei-futuri-migliori-talenti-del-calcio-internazionale_62ImZtGGeXYA1tqQDmbCB3' className='iconReviews1'>
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.ots.at/presseaussendung/OTS_20211209_OTS0082/tokenchampions-legt-den-ersten-
                investmentfonds-fuer-den-erwerb-und-die-verwaltung-der-bildrechte-der-groessten-fussballtalente-von-morgen-auf' className='iconReviews2'>
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.prnewswire.com/news-releases/tokenchampions-launches-the-first-investment-fund-created-to-acquire-and-manage-the-image-rights-of-tomorrows-top-football-talents-301440960.html' className='iconReviews3'></a>
            </div>
            <div className='SectEightR marginMob'>
              <a target="_blank" rel="noreferrer" href='https://thetokenizer.io/2021/12/13/tokeny-powers-the-first-compliant-tokenization-of-football-players-image-rights/' className='iconReviews4'></a>
              <a target="_blank" rel="noreferrer" href='https://es.rankiapro.com/tokenchampions-lanza-fondo-inversion-adquirir-derechos-imagen-talentos-futbol-internacional/' className='iconReviews5'></a>
              <a target="_blank" rel="noreferrer" href='https://finance.yahoo.com/news/tokenchampions-launches-first-investment-fund-090800520.html' className='iconReviews6'></a>
            </div>
          </div>
      </section>
      <FooterSimple />
    </div>
  )
}
