import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/layout/header/Header';
import Home from './components/Pages/Home/Home';
import DataSheet from './components/Pages/DataSheet/DataSheet';
import Players from './components/Pages/Players/Players';
import History from './components/Pages/History/History';
import AboutUs from './components/Pages/AboutUs/AboutUs';
import ContactS from './components/Pages/ContactS/ContactS';
import LoginForm from './components/loginForm/LoginForm';
import SignIn from './components/SignIn/SignIn';
import TokenSell from './components/tokensSell/TokensSell';

import { Amplify } from 'aws-amplify';
/* import { Authenticator } from '@aws-amplify/ui-react'; */
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);


function App() {
  /* const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    AssessLoggedInState()
  }, [])

  const AssessLoggedInState = () => {
    Auth.currentAuthenticatedUser().then(() => {
      setLoggedIn(true);
    }).catch(() => {
      setLoggedIn(false);
    })
  }; */

  /* const signOut = async () => {
    try {
      await Auth.signOut();
      setLoggedIn(false);
    } catch (error) {
      console.log('error signing out', error);
    }
  }; */

  /* const signOut = async () => {
    try {
        await Auth.signOut();
        console.log('Deslogueo Correcto');
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }

  const onSignIn = () => {
    setLoggedIn(true);
    console.log('estoy loggeado');
  } */



  return (
    <div className="">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/dataSheet' element={<DataSheet />} />
        <Route path='/players' element={<Players />} />
        <Route path='/history' element={<History />} />
        <Route path='/aboutUs' element={<AboutUs />} />
        <Route path='/contact' element={<ContactS />} />
        <Route path='/login' element={<LoginForm />} />
        <Route path='/profile' element={<SignIn />} />
        <Route path='/tokenSell' element={<TokenSell />} />
      </Routes>
    </div>
  );
}

export default App;
