import React from "react";
import "./Loader.css";

const Loader = ({classNm}) => {
    return (
        <>
            <div className={`lds-ring ${classNm}`}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    )
};

export default Loader;