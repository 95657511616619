import React from 'react';/* 
import '../../Pages/Home/Home.css'; */
import '../loginForm/LoginForm.css';
import { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';

// >>New - Configuring Auth Module
Auth.configure(awsconfig);

export default function LoginForm(signUp) {
  return (
    <div className='contContact'>
      <div className='compHeadM'></div>
      <section className='homeSectNine'>
          <div className='contForm'>
            <form className='formLogin'>
              <div className='contTitleLogo'>
                <div className='contLogoLogin'></div>
                <p className='contTitleFormLogin'>CREA TU CUENTA</p>
                <p className='contSubTitleFormLogin'>Bienvenido a Token Champions</p>
              </div>
              <div className='contInputs'>
                <input className='inputfstSectForm' placeholder='NOMBRE:'></input>
                <input className='inputfstSectForm marginMob2' placeholder='APELLIDO(S):'></input>
              </div>
              <br></br> <br></br>
              <div className='contInputMailLogin'>
                <input className='inputfstSectFormLogin' placeholder='E-MAIL:'></input>
              </div>
              <br></br> <br></br>
              <div className='contInputs'>
                <input className='inputfstSectForm' placeholder='CONTRASEÑA:'></input>
                <input className='inputfstSectForm marginMob2' placeholder='REPETIR CONTRASEÑA:'></input>
              </div>
              <br></br> <br></br>
                <button className='buttonRegGoogle'>REGISTRO CON GOOGLE</button>
                <button className='buttonRegFB'>REGISTRO CON FACEBOOK</button>
              <br></br>
              <div className='contButtonSendFormLogin'>
                <button onClick={signUp} className='btnSendFormLogin'>ENVIAR</button>
              </div>
              
            </form>
          </div>
        </section>
    </div>
  )
}