import React from 'react';
import './History.css';
import FooterSimple from '../../layout/FooterSimple/FooterSimple';


export default function History() {
  return (
    <div className='contHistory'>
      <div className='compHeadM'></div>
      <section className='historySectOne'>
        <div className='contleftSectOneHist'>
          <p className='txtTitleHist'> <span className='txtfontSHist'>LA GRAN HISTORIA</span> <br></br> DETRÁS DE <br></br> TOKENCHAMPIONS</p>
        </div>
        <div className='contTxtSectOneHist'>
          <div className='contComImg'>
            <div className='comImg'></div>
          </div>
          <br></br>
          <p className='txtSectOneHist'>
            En el mundo del fútbol hay especialidades y fichar es una de ellas, muy importante para el devenir de un club. 
            Es un puesto clave y de sus decisiones sobre a quién se ficha o a quién se traspasa dependerá parte importante del éxito futbolístico y económico de la entidad. 
            Acertar con la persona es vital. 
            Debe ser un experto, el mejor si es posible. Ponerse en manos de aficionados se paga caro.
          </p>
          <br></br>
          <div className='contComImg2'>
            <div className='comImg2'></div>
          </div>
          <br></br>
          <p className='txtNameJosep'>JOSEP MARIA MINGUELLA</p>
        </div>
      </section>
      <section className='historySectTwo'>
        <div className='contLSect2H'></div>
        <div className='contRSect2H'>
          <p className='txtJMM'>EN TODA LA HISTORIA DEL FC BARCELONA, UNA SOLA PERSONA HA PROTAGONIZADO CASI TODOS 
           LOS GESTIONES DE FICHAJE DE LAS MÁXIMAS ESTRELLAS DEL UNIVERSO AZULGRANA.
           JOSEP MARIA MINGUELLA. SOCIO NÚMERO 982, CON 70 AÑOS DE ANTIGÜEDAD.</p>
        </div>
      </section>
      <section className='historySectTwo bgColor_353534'>
        <div className='contLSect2H2'>
          <div className='contDescrptionPhoto'>
            <div className='contBordersImgDesc'>
              <p className='txtDescriptionPhotos'>Josep Maria Minguella, Jorge Cyterszpiler <br></br>
                  y Diego Armando Maradona 1982.</p>
            </div>
          </div>
        </div>
        <div className='contRSect2H bgColor_353534'>
          <p className='txtJMM'>Josep Maria Minguella, es uno de los agentes más importantes de la historia del fútbol mundial, 
            el hombre que descubrió y trajo a Diego Armando Maradona al Barça, que ayudó a que Lionel Messi no se escapará y fichara. 
            También fue quien logró que Hansi Krankl, Romário, Hristo Stoichkov, Rivaldo, Luis Figo y Gica Hagi vistieran de azulgrana.</p>
        </div>
      </section>
      <section className='historySectTwo bgColor_rgaYellow'>
        <div className='contLSect2H2 bgIMGSect4'>
          <div className='contDescrptionPhoto'>
            <div className='contBordersImgDesc'>
              <p className='txtDescriptionPhotos'>Minguella y Josep Guardiola inician <br></br> su incorporación al Barcelona, 1996.</p>
            </div>
          </div>
        </div>
        <div className='contRSect2H bgColor_rgaYellow'>
          <p className='txtJMM colorTxtGrey'>Josep Maria fue agente de Pep Guardiola, tuvo una relación especial con Johan Cruyff,
            sabe mejor que nadie lo que es el “ADN” Barça, no se ha perdido una final de su equipo en España, Europa y el mundo.
            Fue jugador y técnico del fútbol base, intérprete con Vic Buckingham, segundo entrenador con Rinus Michels, 
            consejero de Josep Lluís Núñez y candidato a la presidencia del Barça</p>
        </div>
      </section>
      <section className='historySectFive'>
        <p className='txtSectFiveHisto'>
          “Mingui” ha sido testigo directo de la historia del fútbol mundial, ha participado en las decisiones más importantes del FC Barcelona, 
          ha sido protagonista del devenir de un club en el que entró como técnico de los juveniles, en la época de Narcís de Carreras de presidente.
        </p>
      </section>
      <section className='historySectSix'>
        <div className='contLSect2H3 '>
          <div className='contDescrptionPhoto colDescPhotoBG'>
            <div className='contBordersImgDesc'>
              <p className='txtDescriptionPhotos'>Josep Maria Minguella, Hristo Stoichkov, <br></br>
                Barcelona 1993.</p>
            </div>
          </div>
        </div>
        <div className='contLSect2H3 '>
          <div className='contDescrptionPhoto colDescPhotoBG'>
            <div className='contBordersImgDesc'>
              <p className='txtDescriptionPhotos'>Josep Maria, Johan Cruyff, Joan Laporta y Stoichkov:
                los creadores del actual <br></br> Barcelona, 2005.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='historySectSix'>
        <div className='contLSect2H3 bgSectSev1 '>
          <div className='contDescrptionPhoto colDescPhotoBG'>
            <div className='contBordersImgDesc'>
              <p className='txtDescriptionPhotos'>Josep Maria Minguella recibe a Rivaldo <br></br>
                en el aeropuerto de Barcelona, 1998. </p>
            </div>
          </div>
        </div>
        <div className='contLSect2H3 bgSectSev2'>
          <div className='contDescrptionPhoto colDescPhotoBG'>
            <div className='contBordersImgDesc'>
              <p className='txtDescriptionPhotos'>Josep Maria Minguella y René Higuita <br></br>
                llegando a La Liga, 1999..</p>
            </div>
          </div>
        </div>
      </section>
      <section className='historySectEight'></section>
      <FooterSimple />
    </div>
  )
}
