/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Home.css';
import FooterSimple from '../../layout/FooterSimple/FooterSimple';
import Contact from '../Contact/Contact';

export default function Home() {
  return (
    <div className='contHome'>
      <div className='compHeadM'></div>
        <section className='homeSectOne'>
          <div className='contTitleHomeSect1'>
            <p className='textTitleHomeSect1'>
              <span className='textTitleHomeSect1Min transition1'>ESTO ES</span> <br></br>
              <span className='textTitleHomeSect1Yellow transition2'>TOKEN</span> <br></br>
              <span className='transition1 FSMob'>CHAMPIONS</span>
            </p>
          </div>
        </section>
        <section className='homeSectTwo'>
          <div className='stl'>
            <p className='txtST'>VAMOS A COMPARTIR <span className='txtSTW'>CONTIGO</span> LOS BENEFICIOS DEL GRAN NEGOCIO DEL FUTBOL MUNDIAL.</p>
          </div>
          <div className='str'>
            <p className='txtSTDesct'>Al adquirir tokens de Tokenchampions inviertes directamente en el negocio de fútbol mundial. Cada token
              representa una participación en un fondo de inversión que adquiere los derechos de imagen de jugadores al inicio de su carrera. Al
              avanzar en su carrera tu inversión se multiplica. <br></br> Somos la empresa que más conocimiento tiene en fútbol mundial:
            </p> <br></br>
            <div className='contBtnST'>
              <a href='https://youtu.be/cAZTDqGY0e8' target="_blank" className='contBtnWVideo' rel="noreferrer">
                <div className='btnWVideo'>VER VIDEO</div>
                <div className='imgCircleArrow'></div>
              </a>
            </div>
          </div>
        </section>
        <section className='homeSectThree'>
          <div className='SThrL'></div>
          <div className='SThrR'>
            <p className='txtSThree'>COMO <br></br> <span className='txtSTW'>FUNCIONA</span></p>
            <br></br>
            <p className='txtSThreeSub'>A través de un fondo de inversión regulado en Luxemburgo, nuestros inversores obtienen rendimiento
              viculados a las carreras de los jugadores jóvenes más promisorios. <br></br> Seleccionados por Josep Maria Minguella, una 
              leyenda en la selección de talento, nos dedicamos a adquirir y hacer crecer el valor de la marca de cada unos de nuestros jugadores.
              Esto aumenta el valor del fondo de inversión en el cual es posible invertir a través de un security token. <br></br> Estás a 
              un paso de ser parte de uno de los negocios más lucrativos en el planeta. <br></br> <br></br>
              Si te quieres quedar fuera del negocio, no veas este video:
            </p>
            <br></br>
            <div className='contBtnST'>
              <a href='https://youtu.be/Ay41otgJOfw' target="_blank" className='contBtnWVideo' rel="noreferrer">
                <div className='btnWVideo'>VER VIDEO</div>
                <div className='imgCircleArrow'></div>
              </a>
            </div>
          </div>
        </section>
        <div className='sectionTextMobile'>
            <p className='txtSThreeSubM'>A través de un fondo de inversión regulado en Luxemburgo, nuestros inversores obtienen rendimiento
              viculados a las carreras de los jugadores jóvenes más promisorios. <br></br> Seleccionados por Josep Maria Minguella, una 
              leyenda en la selección de talento, nos dedicamos a adquirir y hacer crecer el valor de la marca de cada unos de nuestros jugadores.
              Esto aumenta el valor del fondo de inversión en el cual es posible invertir a través de un security token. <br></br> Estás a 
              un paso de ser parte de uno de los negocios más lucrativos en el planeta.
            </p>
        </div>
        <section className='homeSectFour'>
          <p className='txtFourSectTitle'> <span className='txtSTW'>ES HORA DE DAR</span> EL PRIMER PASO</p>
          <br></br>
          <div className='contSubtitleSectFour'>
            <div className='SectFL'></div>
            <div className='SectFR'>
              <p className='txtFourSectSubTitle'>
                Y CON SÓLO 100 € <br></br>
                <span className='txtSTW'>PASA DE SER HINCHA,</span> <br></br>
                <span className='txtSTW'>A SER INVERSIONISTA.</span>
              </p>
            </div>
          </div>
        </section>
        <section className='homeSectFive'>
          <div className='contInconsSectFive'>
            <div className='icon1SectFive'></div>
            <div className='lineSectFive'></div>
            <div className='icon2SectFive'></div>
            <div className='lineSectFive'></div>
            <div className='icon3SectFive'></div>
          </div>
          <div className='contTxtSectFive'>
            <div className='txtContSFive'>
              <div className='contIconMob'>
                <div className='icon1SectFive'></div>
              </div>
              <p className='txtTitleSectFive'>CREA TU CUENTA</p>
              <p className='txtSectFive'>Los tokens se adquieren exclusivamente en tokenchampions.com, crea tu cuenta para tener acceso a 
                la plataforma.
              </p>
              <br></br>
              <div className='contBtnSFive'>
                <div className='contBtnStartSFive'>
                  <button className='btnWVideo222'>EMPIEZA AQUÍ</button>
                <div className='imgCircleArrow'></div>
              </div>
            </div>
            </div>
            <div className='txtContSFive'>
              <div className='contIconMob paddMob'>
                <div className='icon2-1SectFive'></div>
              </div>
              <p className='txtTitleSectFive'>CALIFICA COMO INVERSOR</p>
              <p className='txtSectFive'>A diferencia de una criptomoneada o un NFT, un security token es un instrumento regulado. Una vez 
                creada tu cuenta, debemos identificarte como inversor para cumplir con las regulaciones vigentes.
              </p>
            </div>
            <div className='txtContSFive'>
              <div className='contIconMob paddMob'>
                <div className='icon3SectFive'></div>
              </div>
              <p className='txtTitleSectFive'>ADQUIERE TUS TOKENS</p>
              <p className='txtSectFive'>Nuestros inversores registrados pueden invertir directamente en el fondo de inversión adquiriendo 
                tokens a partir de 100€ (50 tokens) hasta finalizar la emisión de 15.000.000 tokens. Se parte del negocio del fútbol ahora.</p>
            </div>
          </div>
        </section>
        <section className='homeSectSix1'>
          <p className='txtTitleSectSix'>NUESTROS SOCIOS</p>
        </section>
        <section className='homeSectSix2'>
          <div className='contSectSixL'>
            <a target="_blank" rel="noreferrer" href='https://tokeny.com/' className='contlogo1SectSix'></a>
            <a target="_blank" rel="noreferrer" href='https://tokeny.com/' className='contlogo2-2SectSix'></a>
            <a target="_blank" rel="noreferrer" href='https://www.euronext.com/en' className='contlogo2SectSix'></a>
          </div>
          <div className='contSectSixR'>
            <a target="_blank" rel="noreferrer" href='https://opportunityfs.com/about-us/' className='contlogo3SectSix'></a>
            <a target="_blank" rel="noreferrer" href='https://www.eversheds-sutherland.com/global/en/who/about-us/index.page' className='contlogo4SectSix'></a>
          </div>
        </section>
        <section className='homeSectSeven'>
          <p className='txtTitleSectSeven'>NOTICIAS</p>
          <br></br>
          <div className='contImgNews'>
            <div className='imgNews1'></div>
            <div className='imgNews2 marginMob'></div>
            <div className='imgNews3 marginMob'></div>
          </div>
        </section>
        <section className='homeSectEight'>
          <p className='txtTitleSectEight'>MIRA AQUÍ LAS RESEÑAS DE PRENSA</p>
          <br></br>
          <div className='conticonsReviews'>
            <div className='SectEightL'>
              <a target="_blank" rel="noreferrer" href='https://www.adnkronos.com/tokenchampions-lancia-il-primo-fondo-di-investimento-creato-per-acquisire-e-gestire-i-diritti-di-immagine-dei-futuri-migliori-talenti-del-calcio-internazionale_62ImZtGGeXYA1tqQDmbCB3' className='iconReviews1'>
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.ots.at/presseaussendung/OTS_20211209_OTS0082/tokenchampions-legt-den-ersten-
                investmentfonds-fuer-den-erwerb-und-die-verwaltung-der-bildrechte-der-groessten-fussballtalente-von-morgen-auf' className='iconReviews2'>
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.prnewswire.com/news-releases/tokenchampions-launches-the-first-investment-fund-created-to-acquire-and-manage-the-image-rights-of-tomorrows-top-football-talents-301440960.html' className='iconReviews3'></a>
            </div>
            <div className='SectEightR marginMob'>
              <a target="_blank" rel="noreferrer" href='https://thetokenizer.io/2021/12/13/tokeny-powers-the-first-compliant-tokenization-of-football-players-image-rights/' className='iconReviews4'></a>
              <a target="_blank" rel="noreferrer" href='https://es.rankiapro.com/tokenchampions-lanza-fondo-inversion-adquirir-derechos-imagen-talentos-futbol-internacional/' className='iconReviews5'></a>
              <a target="_blank" rel="noreferrer" href='https://finance.yahoo.com/news/tokenchampions-launches-first-investment-fund-090800520.html' className='iconReviews6'></a>
            </div>
          </div>
        </section>
        <Contact></Contact>
        <FooterSimple />
    </div>
  )
}
