/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import '../../Pages/Home/Home.css';
import Loader from '../../loader/Loader';

export default function Contact() {

  const [nameContact, setNameContact] = useState('');
  const [phoneContact, setPhoneContact] = useState('');
  const [mailContact, setMailContact] = useState('');
  const [messageContact, setMessageContact] = useState('');
  const [txtConfirmationContact, setTxtConfirmationContact] = useState('');

  const [loaderHiden, setLoaderHiden] = useState('dnonee');

  const createContact = (async() => {
    setTxtConfirmationContact('');
    if (nameContact === '' || phoneContact === '' || mailContact === '' || messageContact === '' ) {
      setTxtConfirmationContact('Por favor llena todos los campos.');
    } else {
      setLoaderHiden('dflexx');
      await fetch('https://bky47t0u08.execute-api.eu-west-3.amazonaws.com/prod/tccore/contacto', {
        method: 'POST',
        body: JSON.stringify({
          // Add parameters here
          "properties": {
            "company": "TKCH",
            "email": mailContact,
            "firstname": nameContact,
            "lastname": "",
            "phone": phoneContact,
            "website": "tkch.com",
            "token_champions_source_url":"https://www.tkch.com",
            "country":"MEX",
            "policy_accepted": true
          },
          "message":messageContact
        }),
        headers: {
        },
      })
      .then(response => {
        if (!response.ok) throw Error(response.status);
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        // Handle data
        setTxtConfirmationContact('Tu mensaje se envió con éxito.');
        setLoaderHiden('dnonee');
        setNameContact('');
        setPhoneContact('');
        setMailContact('');
        setMessageContact('');
      })
      .catch((error) => {
        console.log(error.message);
        setLoaderHiden('dnonee');
        setTxtConfirmationContact('Ocurrió un error, por favor intentelo de nuevo.');
      });
    }
  });

  return (
    <div className='contContact'>
      <div className='compHeadM'></div>
      <section className='homeSectNine'>
          <br></br>
          <div className='contForm'>
            <form className='form'>
              <p className='txtTitleSectNine'>CONTÁCTANOS</p>
              <br></br>
              <div className='contInputs'>
                <input onChange={e => setNameContact(e.target.value)} value={nameContact} className='inputfstSectForm' placeholder='NOMBRE:'></input>
                <br></br>
                <input onChange={e => setPhoneContact(e.target.value)} value={phoneContact} className='inputfstSectForm' placeholder='TELÉFONO:' type='number'></input>
              </div>
              <br></br>
              <div className='contInputMail'>
                <input onChange={e => setMailContact(e.target.value)} value={mailContact} className='inputfstSectFormLogin' placeholder='E-MAIL:' type='mail'></input>
              </div>
              <br></br>
              <input onChange={e => setMessageContact(e.target.value)} value={messageContact} className='inputfstSectFormLogin' placeholder='MENSAJE:'></input>
              <br></br>
              <p className='txtErrorSI'>{txtConfirmationContact}</p>
              <div className='contButtonSendForm'>
                <a className='btnSendFormLogin1' onClick={createContact} >ENVIAR <Loader classNm={loaderHiden}/></a>
              </div>
            </form>
          </div>
        </section>
    </div>
  )
}
