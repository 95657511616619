import React, { useState, useEffect } from 'react';
import Button from '../../Button/Button';
import './Header.css';
import { Link } from 'react-router-dom';
import { useLocalStorage } from '../../../useLocalStorage';

export default function Header( props ) {

  const [textSignIn, setTextSignIn] = useLocalStorage('textSignIn', 'CREA TU CUENTA');
  const [textSignInHidden, setTextSignInHidden] = useLocalStorage('textSignInHidden', '¿YA TIENES CUENTA? ENTRA AQUÍ PARA VER TU SALDO EN TOKENS');

  const [topBar, setTopBar] = useState('btnBorderTop');
  const [topBar1, setTopBar1] = useState('btnBorderNone');
  const [topBar2, setTopBar2] = useState('btnBorderNone');
  const [topBar3, setTopBar3] = useState('btnBorderNone');
  const [topBar4, setTopBar4] = useState('btnBorderNone');
  const [topBar5, setTopBar5] = useState('btnBorderNone');

  const [topBarM, setTopBarM] = useState('btnBorderTopMob');
  const [topBar1M, setTopBar1M] = useState('btnBorderNoneMob');
  const [topBar2M, setTopBar2M] = useState('btnBorderNoneMob');
  const [topBar3M, setTopBar3M] = useState('btnBorderNoneMob');
  const [topBar4M, setTopBar4M] = useState('btnBorderNoneMob');
  const [topBar5M, setTopBar5M] = useState('btnBorderNoneMob');

  const [buttonMenuMobile, setButtonMenuMobile] = useState('hamburger hamburger--spin');
  const [panelMob, setPanelMob] = useState('panel');

  const setBar = () => {
    setTopBar('btnBorderTop');
    setTopBar1('btnBorderNone');
    setTopBar2('btnBorderNone');
    setTopBar3('btnBorderNone');
    setTopBar4('btnBorderNone');
    setTopBar5('btnBorderNone');

    setTopBarM('btnBorderTopMob');
    setTopBar1M('btnBorderNoneMob');
    setTopBar2M('btnBorderNoneMob');
    setTopBar3M('btnBorderNoneMob');
    setTopBar4M('btnBorderNoneMob');
    setTopBar5M('btnBorderNoneMob');

    window.scrollTo( 0, 0 );
  };
  const setBar1 = () => {
    setTopBar('btnBorderNone');
    setTopBar1('btnBorderTop');
    setTopBar2('btnBorderNone');
    setTopBar3('btnBorderNone');
    setTopBar4('btnBorderNone');
    setTopBar5('btnBorderNone');

    setTopBarM('btnBorderNoneMob');
    setTopBar1M('btnBorderTopMob');
    setTopBar2M('btnBorderNoneMob');
    setTopBar3M('btnBorderNoneMob');
    setTopBar4M('btnBorderNoneMob');
    setTopBar5M('btnBorderNoneMob');

    window.scrollTo( 0, 0 );
  };
  const setBar2 = () => {
    setTopBar('btnBorderNone');
    setTopBar1('btnBorderNone');
    setTopBar2('btnBorderTop');
    setTopBar3('btnBorderNone');
    setTopBar4('btnBorderNone');
    setTopBar5('btnBorderNone');

    setTopBarM('btnBorderNoneMob');
    setTopBar1M('btnBorderNoneMob');
    setTopBar2M('btnBorderTopMob');
    setTopBar3M('btnBorderNoneMob');
    setTopBar4M('btnBorderNoneMob');
    setTopBar5M('btnBorderNoneMob');

    window.scrollTo( 0, 0 );
  };
  const setBar3 = () => {
    setTopBar('btnBorderNone');
    setTopBar1('btnBorderNone');
    setTopBar2('btnBorderNone');
    setTopBar3('btnBorderTop');
    setTopBar4('btnBorderNone');
    setTopBar5('btnBorderNone');

    setTopBarM('btnBorderNoneMob');
    setTopBar1M('btnBorderNoneMob');
    setTopBar2M('btnBorderNoneMob');
    setTopBar3M('btnBorderTopMob');
    setTopBar4M('btnBorderNoneMob');
    setTopBar5M('btnBorderNoneMob');

    window.scrollTo( 0, 0 );
  };
  const setBar4 = () => {
    setTopBar('btnBorderNone');
    setTopBar1('btnBorderNone');
    setTopBar2('btnBorderNone');
    setTopBar3('btnBorderNone');
    setTopBar4('btnBorderTop');
    setTopBar5('btnBorderNone');

    setTopBarM('btnBorderNoneMob');
    setTopBar1M('btnBorderNoneMob');
    setTopBar2M('btnBorderNoneMob');
    setTopBar3M('btnBorderNoneMob');
    setTopBar4M('btnBorderTopMob');
    setTopBar5M('btnBorderNoneMob');

    window.scrollTo( 0, 0 );
  };
  const setBar5 = () => {
    setTopBar('btnBorderNone');
    setTopBar1('btnBorderNone');
    setTopBar2('btnBorderNone');
    setTopBar3('btnBorderNone');
    setTopBar4('btnBorderNone');
    setTopBar5('btnBorderTop');

    setTopBarM('btnBorderNoneMob');
    setTopBar1M('btnBorderNoneMob');
    setTopBar2M('btnBorderNoneMob');
    setTopBar3M('btnBorderNoneMob');
    setTopBar4M('btnBorderNoneMob');
    setTopBar5M('btnBorderTopMob');

    window.scrollTo( 0, 0 );
  };

  const menuHam = () => {
    if (buttonMenuMobile === 'hamburger hamburger--spin') {
      setButtonMenuMobile('hamburger hamburger--spin is-active');
      setPanelMob('panel is-active');

    } else {
      setButtonMenuMobile('hamburger hamburger--spin')
      setPanelMob('panel');
      window.scrollTo( 0, 0 );
    }
    
  };

  useEffect(() => {
    console.log(textSignIn);
  }, []);


  return (
    <div className='contHeaderGlobal'>
      <div className='headerCont'>
          <div className='navCont'>
              <Link to='/' onClick={setBar} className='contLogo'></Link>
              <div className='spaceMenu'></div>
              <div className='contMenu'>
                  <div className='menuTop'>
                    <Link className='btnLogIn2' to='/profile'><button className='btnLogIn'>{textSignInHidden}</button></Link>
                    <div className='spaceMenu'></div>
                    <Link className='btnCreateAccountLink' to='/profile'><button className='btnCreateAccount'>{textSignIn}</button></Link>
                    <div className='setlanguageCont'>
                      <Button description='ES' borderTop='btnWsitchLanguage'></Button>
                      <Button description='ENG' borderTop='btnWsitchLanguage'></Button>
                      <Button description='FR' borderTop='btnWsitchLanguage2'></Button>
                    </div>
                  </div>
                  <div className='menuBoot'>
                    <Link className='linksNavBar' to='/'><Button functOncl={setBar} description='INICIO' borderTop={topBar}></Button></Link>
                    <Link className='linksNavBar' to='/dataSheet'><Button functOncl={setBar1} description='FICHA TÉCNICA' borderTop={topBar1}></Button></Link>
                    <Link className='linksNavBar' to='/players'><Button functOncl={setBar2} description='JUGADORES' borderTop={topBar2}></Button></Link>
                    <Link className='linksNavBar' to='/history'><Button functOncl={setBar3} description='HISTORIA' borderTop={topBar3}></Button></Link>
                    <Link className='linksNavBar' to='/aboutUs'><Button functOncl={setBar4} description='SOBRE NOSOTROS' borderTop={topBar4}></Button></Link>
                    <Link className='linksNavBar' to='/contact'><Button functOncl={setBar5} description='CONTACTO' borderTop={topBar5}></Button></Link>
                  </div>
              </div>
          </div>
      </div>
      <div className='headerContMobile'>
        <div className='spaceMob'>
          <Button description='ES' borderTop='btnWsitchLanguage'></Button>
          <Button description='ENG' borderTop='btnWsitchLanguage'></Button>
          <Button description='FR' borderTop='btnWsitchLanguage2'></Button>
        </div>
        <Link to='/' onClick={setBar} className='contLogoMob'></Link>
        <div className='panel-btn'>
          <button onClick={menuHam} className={buttonMenuMobile} type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div className={panelMob}>
        <div className='menuMobileCont'>
          <div className='menuMobile'>
            <Link onClick={menuHam} className='linksNavBarMob' to='/'><Button functOncl={setBar} description='INICIO' borderTop={topBarM}></Button></Link>
            <Link onClick={menuHam} className='linksNavBarMob' to='/dataSheet'><Button functOncl={setBar1} description='FICHA TÉCNICA' borderTop={topBar1M}></Button></Link>
            <Link onClick={menuHam} className='linksNavBarMob' to='/players'><Button functOncl={setBar2} description='JUGADORES' borderTop={topBar2M}></Button></Link>
            <Link onClick={menuHam} className='linksNavBarMob' to='/history'><Button functOncl={setBar3} description='HISTORIA' borderTop={topBar3M}></Button></Link>
            <Link onClick={menuHam} className='linksNavBarMob' to='/aboutUs'><Button functOncl={setBar4} description='SOBRE NOSOTROS' borderTop={topBar4M}></Button></Link>
            <Link onClick={menuHam} className='linksNavBarMob' to='/contact'><Button functOncl={setBar5} description='CONTACTO' borderTop={topBar5M}></Button></Link>
          </div>
          <div className='menuMobileL'>
            <button className='btnLogInMob'>{textSignInHidden}</button>
            <Link onClick={menuHam} className='btnCreateAccountLink1' to='/profile'><button className='btnCreateAccountMob'>{textSignIn}</button></Link>
          </div>
        </div>
      </div>
    </div>
  )
}
