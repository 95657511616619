/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import './DataSheet.css';
import FooterSimple from '../../layout/FooterSimple/FooterSimple';

export default function DataSheet() {
  return (
    <div className='contDataSheet'>
      <div className='compHeadM'></div>
      <section className='SectOneDS'>
        <div className='titleDS'>
          <p className='pTitleDS'> OBJETIVO <br></br>
            TOKENCHAMPIONS <br></br><br></br> </p>
        </div>
        <div className='subTitleDS'>
          <p className='pSubTitleDS'>
            DEMOCRATIZAR EL NEGOCIO DEL FÚTBOL <br></br>
            Y COMPARTIRLO CON UN UNIVERSO DE <br></br> <br></br>
            <span className='pSubTitleDS2'>
              <span className='pSubTitleDSY'> 4 MIL MILLONES </span><br></br>
              DE SEGUIDORES
            </span>
          </p>
        </div>
      </section>
      <section className='SectTwoDS'>
        <div className='SectTwoDSL'>
          <p className='txtSectTwoDSL'>
            <span className='txtSectTwoDSLW'>TOKENCHAMPIONS, </span>
            EL PRIMER FONDO DE INVERSIÓN TOKENIZADO, CREADO PARA ADQUIRIR y
            GESTIONAR LOS DERECHOS DE IMAGEN DE LOS MEJORES TALENTOS DEL
            FÚTBOL INTERNACIONAL.
          </p>
        </div>
        <div className='SectTwoDSR'>
          <div className='imgSect2DS'></div>
        </div>
      </section>
      <section className='txtTitleBGG'>
        <p className='textTDS'>LOS ACTIVOS DEL FONDO</p>
      </section>
      <section className='SectThreeDS'>
        <div className='contIMGSThreeDS'></div> <br></br>
        <p className='txtSectThreeDS'> <span className='txtBold'>Los activos del Fondo </span> 
          están representados por contratos de derechos de Imagen de estrellas del fútbol mundial 
          que son seleccionadas por un equipo de expertos con una dilatada experiencia en el sector y el conocimiento 
          acumulado de <span className='txtBold'>Josep Maria Minguella </span> , agente de jugadores de fútbol, históricamente vinculado al FC Barcelona y mundialmente 
          reconocido por su capacidad de descubrir y hacer crecer talentos como <span className='txtBold'>Maradona, Messi, Stoichkov, Rivaldo o Romario</span> y 
          actuales figuras como <span className='txtBold'>Ansu Fati, Alejandro Balde, Malcolm o Raúl Moro.</span>  
        </p>
      </section>
      <section className='txtTitleBGG'>
        <p className='textTDS'>OBJETIVOS DE GESTIÓN</p>
      </section>
      <section className='SectFourDS'>
        <div className='contImgSectFourDS'>
          <div className='imgSEctFourDS'></div>
        </div>
        <div className='contTxtSectFourDS'>
          <p className='contTxtSectFourDStxt'>
            Los objetivos de <span className='txtBold'>Tokenchampions</span>, para los próximos 3 años, son gestionar una cartera de 
            alrededor de <span className='txtBold'>300 jugadores</span> estrella. Talentos que serán seleccionados, en función de su 
            progresión deportiva, entre miles de jugadores que, hoy se tienen identificados y a los que 
            se les está dando seguimiento a través de la red de agentes internacionales del 
            equipo Tokenchampions.
          </p>
        </div>
      </section>
      <section className='SectFourDS SectFourDS2'>
        <div className='contImgSectFourDS contImgSectFourDS2 transparentStyle'>
          <div className='imgSEctFourDS bgSectFiveDS'></div>
        </div>
        <div className='contTxtSectFourDS'>
          <p className='contTxtSectFourDStxt blackColor'>
            En esta primera emisión, <span className='txtBold'>Tokenchampions (TKCH 1)</span> incorpora jóvenes figuras del fútbol actual, 
            poseedores de un enorme potencial de crecimiento, que formarán parte de un conjunto de jugadores de primera 
            división con edades comprendidas entre 18 y 25 años, que actualmente están jugando en Ligas como la argentina, 
            brasileña y europeas.
          </p>
        </div>
      </section>
      <section className='SectTwoDS SectTwoDS2'>
        <div className='SectTwoDSL SectTwoDSL2'>
          <p className='txtSectTwoDSL'>
            <span className='txtBold'>
              LA PARTICIPACIÓN EN EL FONDO DE INVERSIÓN 
              SE REALIZA A TRAVÉS DE SECURITY TOKENS, 
              BASADOS EN LA TECNOLOGÍA BLOCKCHAIN 
              MÁS FIABLE Y SEGURA.
            </span>
          </p>
        </div>
        <div className='SectTwoDSR SectTwoDSR2'></div>
      </section>
      <section className='txtTitleBGG'>
        <p className='textTDS'>ESTRUCTURACIÓN DEL FONDO, RANTABILIDAD, RENDIMIENDOS</p>
      </section>
      <section className='SectEightDS'>
        <div className='contStImgSectEDS'>
          <div className='contLSEDS'></div>
          <div className='contRSEDS'>
            <p className='txtGoldBar'>
              • Valor TKCH 1 primera emisión: 2 euros <br></br>
              • Emisión limitada: 15 millones de Tokens <br></br>
              • Monto primera emisión: 30 millones de euros <br></br>
              • Periodo de madurez TKCH 1: 5 años
            </p>
          </div>
        </div>
        <div className='contStImgSectEDS mTopDesktop2'>
          <div className='contLSEDS img2SEDS'></div>
          <div className='contRSEDS img2SEDS2'>
            <p className='txtGoldBar2'>
              La distribución de la colocación de los tokens se ha determinado en: <br></br>
              • 22 millones de euros para inversores   institucionales (Ticket mínimo 125 mil euros) <br></br>
              • 8 millones de euros para el mercado retail   (Ticket mínimo 100 euros)
            </p>
          </div>
        </div>
        <div className='contStImgSectEDS mTopDesktop'>
          <div className='contLSEDS img3SEDS'></div>
          <div className='contRSEDS img3SEDS3'>
            <p className='txtGoldBar3'>
              En esta primera emisión de tokens, para los partícipes tenedores de tokens, se aplica la siguiente fórmula de reparto de dividendos: <br></br>
              • 5 % de rentabilidad anual sobre el valor de emisión primaria. <br></br>
              • 50 % de los rendimientos al finalizar el periodo de madurez de los tokens. De este importe se deducirán los avances del 5 % que se hayan cobrado.
            </p>
          </div>
        </div>
        <div className='contStImgSectEDS'>
          <div className='contLSEDS img4SEDS'></div>
          <div className='contRSEDS img4SEDS4'>
            <p className='txtGoldBar4'>
              Además de la revalorización de los tokens, vinculada al au-mento de valor de los activos del Fondo, 
              los tokens podrán aumentar su valor en función de la oferta y la demanda ge-nerada por el mercado secundario.
              • El Billboard de Tokenchampions es una plataforma de compraventa sin intermediarios que le da flexibilidad
               a la inversión, ya que permite a los inversores tener una forma conveniente de vender sus tokens antes de finalizado el pe-riodo de madurez.
            </p>
          </div>
        </div>
      </section>
      <section className='txtTitleBGG'>
        <p className='textTDS'>REVALORIZACIÓN DEL FONDO</p>
      </section>
      <section className='SectTwoDS SectTwoDS2'>
        <div className='SectTwoDSL SectTwoDSL3'>
          <p className='txtSectTwoDSL'>
            La  experiencia  acumulada  en  las  últimas  décadas  ha  permitido  desarrollar  la <span className='txtBold'>Metodología Tokenchampions</span>  
            ,  que  permite  idenficar  el  perfil  personal  de  cada  jugador  de  forma óptima. <br></br> <br></br>
            Utilizando  este  perfil  se  crea  una  estrategia  única,  especialmente diseñada para incrementar el 
            valor de la imagen del  jugador  a  través  de  su  vida  profesional,  tomando  en  cuenta las necesidades del mercado. 
            Pero, sobre todo, las aspiraciones,  preferencias  y  necesidades  personales  de  cada jugador. <br></br> <br></br>
            Al combinar procesos, tecnología y la experiencia del equipo de Josep Maria Minguella, <span className='txtBold'>Tokenchampions</span> Tokenchampions obtiene una 
            base sólida y confiable para incrementar el valor de los activos del Fondo.
          </p>
        </div>
        <div className='SectTwoDSR SectTwoDSR3'></div>
      </section>
      <section className='txtTitleBGG'>
        <p className='textTDS'>SEGURIDAD LEGAL</p>
      </section>
      <section className='SectTwoDS SectTwoDS2'>
        <div className='SectTwoDSR1-2'>
          <div className='SectTwoDSR2 SectTwoDSR4 SectTwoDSR42'></div>
        </div>
        <div className='SectTwoDSL SectTwoDSL4'>
          <p className='txtSectTwoDSL'>
            <span className='txtBold'>CLAVE PARA GENERAR CONFIANZA</span> <br></br> <br></br>
              <span className='txtBold'>Tokenchampions</span>  ha  sido  estructurado  en  
              Luxemburgo  a  partir de la normativa europea aplicable a vehículos de in-versión, ofreciendo 
              las garantías de un mercado regulado gracias  a  la  aprobación  de  una  ley  en  Luxemburgo  que  
              permite el uso de blockchain en la emisión y circulación de valores. <br></br> <br></br> El  21  de  enero  de  2021  el  
              Parlamento  de  Luxemburgo  aprobó  el  proyecto  de  ley  7637  (que  se  denominará  Ley  Blockchain II de 2021).
              La Ley Blockchain II de 2021 permite: (a) la emisión de valores desmaterializados directamente  
              en  dispositivos  DLT  (tecnología  de  contabilidad  distribuida) y (b) abre la función de responsable central de 
              la cuenta para registrar y gestionar las emisiones DLT de valores de deuda no cotizados a cualquier entidad de crédito 
              o empresa de inversión de la UE.
          </p>
        </div>
      </section>
      <section className='homeSectEight'>
          <p className='txtTitleSectEight'>MIRA AQUÍ LAS RESEÑAS DE PRENSA</p>
          <br></br>
          <div className='conticonsReviews'>
            <div className='SectEightL'>
              <a target="_blank" rel="noreferrer" href='https://www.adnkronos.com/tokenchampions-lancia-il-primo-fondo-di-investimento-creato-per-acquisire-e-gestire-i-diritti-di-immagine-dei-futuri-migliori-talenti-del-calcio-internazionale_62ImZtGGeXYA1tqQDmbCB3' className='iconReviews1'>
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.ots.at/presseaussendung/OTS_20211209_OTS0082/tokenchampions-legt-den-ersten-
                investmentfonds-fuer-den-erwerb-und-die-verwaltung-der-bildrechte-der-groessten-fussballtalente-von-morgen-auf' className='iconReviews2'>
              </a>
              <a target="_blank" rel="noreferrer" href='https://www.prnewswire.com/news-releases/tokenchampions-launches-the-first-investment-fund-created-to-acquire-and-manage-the-image-rights-of-tomorrows-top-football-talents-301440960.html' className='iconReviews3'></a>
            </div>
            <div className='SectEightR marginMob'>
              <a target="_blank" rel="noreferrer" href='https://thetokenizer.io/2021/12/13/tokeny-powers-the-first-compliant-tokenization-of-football-players-image-rights/' className='iconReviews4'></a>
              <a target="_blank" rel="noreferrer" href='https://es.rankiapro.com/tokenchampions-lanza-fondo-inversion-adquirir-derechos-imagen-talentos-futbol-internacional/' className='iconReviews5'></a>
              <a target="_blank" rel="noreferrer" href='https://finance.yahoo.com/news/tokenchampions-launches-first-investment-fund-090800520.html' className='iconReviews6'></a>
            </div>
          </div>
      </section>
      <FooterSimple />
    </div>
  )
}
