import React from 'react';
import '../Home/Home.css';
import './Players.css';
import FooterSimple from '../../layout/FooterSimple/FooterSimple';

export default function Players() {
  return (
    <div className='contPlayers'>
      <div className='compHeadM'></div>
      <section className='playersSectOne'>
        <div className='CPS1L'>
          <p className='txtTitleCPS1'>SELECCIÓN <br></br> <span className='txtTitleCPS1-2'>TOKENCHAMPIONS</span></p>
        </div>
        <div className='CPS1R'>
          <p className='txtSubTitleCPS1'>Esta es nuestra <span className='txtSubTitleCPS1Y'>SELECCIÓN TOKENCHAMPIONS</span> , a la que se suman los nuevos valores del futbol mundial. 
          La <span className='txtSubTitleCPS1Y'>PRE SELECCIÓN TOKENCHAMPIONS</span> , está integrada por novedosos prospectos, candidatos a ser seleccionados.</p>
        </div>
      </section>
      <div className='contSectPlayers'>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg1'></div>
              <div className='position'>
                <p className='txtPosition'>OLIMPYQUE MARSEILLE<br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub1'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>GERSON <span className='positionWhite'>SANTOS <br></br> DA SILVA (24)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg2'></div>
              <div className='position'>
                <p className='txtPosition'>LAZIO<br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub2'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>RAÚL <span className='positionWhite'>MORO <br></br> PRESCOLI (19)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat3'></div>
              <div className='playerImg urlPlayerImg3'></div>
              <div className='position'>
                <p className='txtPosition'>FC CROTONE<br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub3'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>BOBBY<br></br> <span className='positionWhite'>ADEKANYE (23)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg4'></div>
              <div className='position'>
                <p className='txtPosition'>CÁDIZ CF<br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub4'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>CARLOS <span className='positionWhite'>ALGARRA <br></br> LÓPEZ (22)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg5'></div>
              <div className='position'>
                <p className='txtPosition'>ATLÉTICO LEVANTE<br></br> <span className='positionWhite'>MEDIO CENTRO</span> </p>
              </div>
              <div className='clubImage urlImageClub5'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>CARLOS<br></br> <span className='positionWhite'>BENÍTEZ (19)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg6'></div>
              <div className='position'>
                <p className='txtPosition'>RCD ESPANYOL <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub6'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>JAVIER <span className='positionWhite'>RODRÍGUEZ <br></br> LANCHO (19)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg7'></div>
              <div className='position'>
                <p className='txtPosition'>ALTÉTICO DE MADRID<br></br> <span className='positionWhite'>MEDIO CENTRO</span> </p>
              </div>
              <div className='clubImage urlImageClub7'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>ALEJANDRO<br></br> <span className='positionWhite'>GINARD (18)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg8'></div>
              <div className='position'>
                <p className='txtPosition'>VILLAREAL <br></br> <span className='positionWhite'>MEDIO CENTRO</span> </p>
              </div>
              <div className='clubImage urlImageClub8'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>MARCOS<br></br> <span className='positionWhite'>SÁNCHEZ (18)</span> </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className='playersSectTwo'>
        <div className='txtSpaceSectT'></div>
        <div className='txtSect2'> 
          <span className='txt1ST'>CONVIÉRTETE EN</span> <br></br>
          <span className='txt2ST paddingDesk'>SELECCIONADOR</span> <br></br>
          <span className='txt1ST paddingDesk'>DEL EQUIPO DE ESTRELLAS <span className='txt2BST'>TOKENCHAMPIONS</span></span> <br></br> <br></br>
          <span className='txt3ST paddingDesk'>Vota por tus jugadores favoritos y escoge tú también las nuevas estrellas del fútbol mundial
            que respaldarán tu inversión a futuro.
          </span>
        </div>
        <div className='ThrdZone'>
          <div className='subSquareBlack'>
            <p className='txt3Trys'>SÓLO TRES VOTOS POR USUARIO</p>
          </div>
        </div>
      </section>
      <section className='playersSectOne2'>
        <div className='CPS1L'>
          <p className='txtTitleCPS1'>PRE-SELECCIÓN <br></br> <span className='txtTitleCPS1-2'>TOKENCHAMPIONS</span></p>
        </div>
        <div className='CPS1R'>
          <p className='txtSubTitleCPS1'>SI DESEAS VOTAR POR ALGUNO DE ELLOS  <span className='txtSubTitleCPS1Y'>REGÍSTRATE</span> COMO USUARIO.</p>
        </div>
      </section>
      <div className='contSectPlayers'>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat9'></div>
              <div className='playerImg urlPlayerImg9'></div>
              <div className='position'>
                <p className='txtPosition'>VfL BOCHUM<br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub9'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>ARMEL <span className='positionWhite'>BELLA <br></br> KOTHCHAP (20)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat10'></div>
              <div className='playerImg urlPlayerImg10'></div>
              <div className='position'>
                <p className='txtPosition'>BORUSSIA M'GLADBACH <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub10'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>RAMY<br></br> <span className='positionWhite'>BENSEBAINI (26)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat11'></div>
              <div className='playerImg urlPlayerImg11'></div>
              <div className='position'>
                <p className='txtPosition'>ALTLANTA UNITED <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub11'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>THIAGO<br></br> <span className='positionWhite'>ALMADA (20)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat11'></div>
              <div className='playerImg urlPlayerImg12'></div>
              <div className='position'>
                <p className='txtPosition'>CA BOCA JUNIORS <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub12'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>AGUSTÍN<br></br> <span className='positionWhite'>ALMENDRA (22)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat11'></div>
              <div className='playerImg urlPlayerImg13'></div>
              <div className='position'>
                <p className='txtPosition'>CA BOCA JUNIORS<br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub12'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>EXEQUIEL<br></br> <span className='positionWhite'>ZEBALLOS (19)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat11'></div>
              <div className='playerImg urlPlayerImg14'></div>
              <div className='position'>
                <p className='txtPosition'>CA BOCA JUNIORS<br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub12'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>MATÍAS<br></br> <span className='positionWhite'>PALACIOS (19)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat11'></div>
              <div className='playerImg urlPlayerImg15'></div>
              <div className='position'>
                <p className='txtPosition'>CA ROSARIO CENTRAL<br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub15'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>GINO<br></br> <span className='positionWhite'>INFANTINO (18)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat11'></div>
              <div className='playerImg urlPlayerImg16'></div>
              <div className='position'>
                <p className='txtPosition'>CA ROSARIO CENTRAL<br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub15'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>FRANCESCO<br></br> <span className='positionWhite'>LO CELSO (22)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat11'></div>
              <div className='playerImg urlPlayerImg17'></div>
              <div className='position'>
                <p className='txtPosition'>CA ROSARIO CENTRAL<br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub15'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>LUCIANO<br></br> <span className='positionWhite'>VERA (20)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg18'></div>
              <div className='position'>
                <p className='txtPosition'>PALMEIRAS <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub18'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>GABRIEL<br></br> <span className='positionWhite'>VERON (19)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg19'></div>
              <div className='position'>
                <p className='txtPosition'>GREMIO<br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub19'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>JEAN PYERRE<br></br> <span className='positionWhite'>CASAGRANDE (23)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg20'></div>
              <div className='position'>
                <p className='txtPosition'>CAGLIARI<br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub19'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>DALBERT<br></br> <span className='positionWhite'>(28)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg21'></div>
              <div className='position'>
                <p className='txtPosition'>INTERNACIONAL <br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub21'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>KAIKE<br></br> <span className='positionWhite'>ROCHA (21)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg22'></div>
              <div className='position'>
                <p className='txtPosition'>MIDTJYLLAND <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub22'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>CHARLES<br></br> <span className='positionWhite'>MATOS (25)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg23'></div>
              <div className='position'>
                <p className='txtPosition'>INTERNACIONAL<br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub21'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>PEDRO<br></br> <span className='positionWhite'>LUCAS (23)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg24'></div>
              <div className='position'>
                <p className='txtPosition'>GUARANI<br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub24'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>VITINHO<br></br> <span className='positionWhite'>(24)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat1'></div>
              <div className='playerImg urlPlayerImg25'></div>
              <div className='position'>
                <p className='txtPosition'>FLUMINENSE<br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub25'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>YAGO<br></br> <span className='positionWhite'>DE PAULA (20)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat26'></div>
              <div className='playerImg urlPlayerImg26'></div>
              <div className='position'>
                <p className='txtPosition'>PALMEIRAS <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub26'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>YAIRO<br></br> <span className='positionWhite'>MORENO (27)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat26'></div>
              <div className='playerImg urlPlayerImg27'></div>
              <div className='position'>
                <p className='txtPosition'>AMERICA DE CALI <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub26'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>GUSTAVO<br></br> <span className='positionWhite'>CARVAJAL (21)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat28'></div>
              <div className='playerImg urlPlayerImg28'></div>
              <div className='position'>
                <p className='txtPosition'>LOSC LILLE <br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub28'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>DOMAGOJ<br></br> <span className='positionWhite'>BRADARIC (22)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat29'></div>
              <div className='playerImg urlPlayerImg29'></div>
              <div className='position'>
                <p className='txtPosition'>LOS ANGELES <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub29'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>JOSÉ<br></br> <span className='positionWhite'>CIFUENTES (23)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat29'></div>
              <div className='playerImg urlPlayerImg30'></div>
              <div className='position'>
                <p className='txtPosition'>SANTOS LAGUNA <br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub30'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>FÉLIX<br></br> <span className='positionWhite'>TORRES (25)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat29'></div>
              <div className='playerImg urlPlayerImg31'></div>
              <div className='position'>
                <p className='txtPosition'>U. CATÓLICA<br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub31'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>ARON<br></br> <span className='positionWhite'>RODRIGUEZ (22)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg32'></div>
              <div className='position'>
                <p className='txtPosition'>ESPANYOL<br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub32'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>GORI<br></br> <span className='positionWhite'>(19)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg33'></div>
              <div className='position'>
                <p className='txtPosition'>ESPANYOL <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub32'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>JOFRE<br></br> <span className='positionWhite'>CARRERAS (20)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg34'></div>
              <div className='position'>
                <p className='txtPosition'>HJK HELSINKI <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub34'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>BIJAN<br></br> <span className='positionWhite'>RADULOVIC (22)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg35'></div>
              <div className='position'>
                <p className='txtPosition'>ATLÉTICO DE MADRID <br></br> <span className='positionWhite'>PORTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub7'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>CHRISTIAN<br></br> <span className='positionWhite'>GOMEZ (21)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg36'></div>
              <div className='position'>
                <p className='txtPosition'>ATLÉTICO DE MADRID <br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub7'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>DAVID<br></br> <span className='positionWhite'>NAVARRO (19)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg37'></div>
              <div className='position'>
                <p className='txtPosition'>HERCULES CF <br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub37'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>JOSÉ<br></br> <span className='positionWhite'>SOLBES (22)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg38'></div>
              <div className='position'>
                <p className='txtPosition'>ESPANYOL <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub32'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>ROGER<br></br> <span className='positionWhite'>GARCIA CAYON (21)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat2'></div>
              <div className='playerImg urlPlayerImg39'></div>
              <div className='position'>
                <p className='txtPosition'>SAN IGNACIO <br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub39'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>MANEL<br></br> <span className='positionWhite'>MARTÍNEZ (20)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat40'></div>
              <div className='playerImg urlPlayerImg40'></div>
              <div className='position'>
                <p className='txtPosition'>NANTES <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub40'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>KOLO<br></br> <span className='positionWhite'>MUANI (23)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat41'></div>
              <div className='playerImg urlPlayerImg41'></div>
              <div className='position'>
                <p className='txtPosition'>AS ROMA <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub41'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>NICO<br></br> <span className='positionWhite'>ZINIOLO (22)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat42'></div>
              <div className='playerImg urlPlayerImg42'></div>
              <div className='position'>
                <p className='txtPosition'>BARCELONA <br></br> <span className='positionWhite'>ARQUERO</span> </p>
              </div>
              <div className='clubImage urlImageClub42'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>LAZAR<br></br> <span className='positionWhite'>CAREVIC (23)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat43'></div>
              <div className='playerImg urlPlayerImg43'></div>
              <div className='position'>
                <p className='txtPosition'>LIBERTAD <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub43'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>IVÁN<br></br> <span className='positionWhite'>FRANCO (21)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat44'></div>
              <div className='playerImg urlPlayerImg44'></div>
              <div className='position'>
                <p className='txtPosition'>NEFTEKHIMIK <br></br> <span className='positionWhite'>DEFENSA</span> </p>
              </div>
              <div className='clubImage urlImageClub44'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>VLADIMIR<br></br> <span className='positionWhite'>MOSKVICHEV (23)</span> </p>
              </div>
            </div>
          </div>
        </section>
        <section className='sectPlayers'>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat45'></div>
              <div className='playerImg urlPlayerImg45'></div>
              <div className='position'>
                <p className='txtPosition'>LOS ANGELES <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub29'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>BRYAN<br></br> <span className='positionWhite'>RODRÍGUEZ (21)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat45'></div>
              <div className='playerImg urlPlayerImg46'></div>
              <div className='position'>
                <p className='txtPosition'>PLAZA COLONIA <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub46'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>LEANDRO<br></br> <span className='positionWhite'>SUHR (24)</span> </p>
              </div>
            </div>
          </div>
          <div className='contDuplexCard'>
            <div className='contCard'>
              <div className='flat urlFlat45'></div>
              <div className='playerImg urlPlayerImg47'></div>
              <div className='position'>
                <p className='txtPosition'>LIBERTAD <br></br> <span className='positionWhite'>DELANTERO</span> </p>
              </div>
              <div className='clubImage urlImageClub43'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>LAUTARO<br></br> <span className='positionWhite'>DE LEÓN (21)</span> </p>
              </div>
            </div>
            <div className='contCard'>
              <div className='flat urlFlat48'></div>
              <div className='playerImg urlPlayerImg48'></div>
              <div className='position'>
                <p className='txtPosition'>ESPANYOL <br></br> <span className='positionWhite'>MEDIO CAMPO</span> </p>
              </div>
              <div className='clubImage urlImageClub32'></div>
              <div className='playerName'>
                <p className='txtPlayerName'>YANGEL<br></br> <span className='positionWhite'>HERRERA (24)</span> </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterSimple />
    </div>
  )
}
