import React from 'react';
import './FooterSimple.css';
import '../Footer/Footer.css';

export default function FooterSimple() {
  return (
    <div className='contFooterDesk'>
      <section className='fstSectFoot'>
        <div className='BtnFS'>
          <p className='txtBF'>LEGAL</p>
          <a target="_blank" rel="noreferrer" href='https://tokenchampions.com/legal.pdf' className='contBtnFstSectF'>
            <div className='btnStSect'>Saber más</div>
            <div className='imgCircleArrow'></div>
          </a>
        </div>
        <div className='BtnFS'>
          <p className='txtBF'>FAQ</p>
          <a target="_blank" rel="noreferrer" href='faq.pdf' className='contBtnFstSectF'>
          <div className='btnStSect'>Saber más</div>
            <div className='imgCircleArrow'></div>
          </a>
        </div>
        <div className='BtnFS'>
          <p className='txtBF'>POLITICA DE PRIVACIDAD</p>
          <a target="_blank" rel="noreferrer" href='https://tokenchampions.com/privacy.pdf' className='contBtnFstSectF'>
          <div className='btnStSect'>Saber más</div>
            <div className='imgCircleArrow'></div>
          </a>
        </div>
      </section>
      <section className='secSectFoot'>
        <div className='contSocialMedia'>
          <a className='linkSocialMedia' href='https://www.facebook.com/Tokenchampions' ><div className='socialMediaIcon'></div></a>
          <a className='linkSocialMedia' href='https://twitter.com/TokenChampions' ><div className='socialMediaIcon1'></div></a>
          <a className='linkSocialMedia' href='https://www.instagram.com/tokenchampions/' ><div className='socialMediaIcon2'></div></a>
          <a className='linkSocialMedia' href='https://www.youtube.com/@tokenchampions' ><div className='socialMediaIcon3'></div></a>
        </div>
      </section>
      <section className='thiSectFoot'>
        <p className='txtTitleContactsSimple'>CONTACTOS DE CONSULTA Y AYUDA</p>
        <div className='contMailsContact'>
            <div className='contMailsContact2'>
                <div className='contMailContactL'>
                    <a target="_blank" rel="noreferrer" href='mailto:info@tokenchampions.com' className='txtMailContact paddMob'>info@tokenchampions.com</a>
                </div>
                <div className='contMailContactR'>
                    <a target="_blank" rel="noreferrer" href='mailto:soporte@tokenchampions.com' className='txtMailContact paddMob'>soporte@tokenchampions.com</a>
                </div>
            </div>
        </div>
      </section>
      <section className='fourthSect'>
        <p className='txtFinalFooter'>© 2020 Tokenchampions SCSp - Luxemburgo | Diseñado por Iconic - Miami, USA | Desarrollo Web por Skorus Solutions, Inc - Canada
         | Powered by Xherstone - México <br></br> info@tokenchampions.com | Rue Robert Stümper, 16, L-2557 Luxemburgo, Gran Ducado de Luxemburgo
         | +18584299145</p>
      </section>
    </div>
  )
}
