/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import './Footer.css';

export default function Footer() {
  return (
    <div className='contFooterDesk'>
      <section className='fstSectFoot'>
        <div className='BtnFS'>
          <p className='txtBF'>LEGAL</p>
          <a target="_blank" rel="noreferrer" href='https://tokenchampions.com/legal.pdf' className='contBtnFstSectF'>
            <div className='btnStSect'>Saber más</div>
            <div className='imgCircleArrow'></div>
          </a>
        </div>
        <div className='BtnFS'>
          <p className='txtBF'>FAQ</p>
          <a target="_blank" rel="noreferrer" href='faq.pdf' className='contBtnFstSectF'>
          <div className='btnStSect'>Saber más</div>
            <div className='imgCircleArrow'></div>
          </a>
        </div>
        <div className='BtnFS'>
          <p className='txtBF'>POLITICA DE PRIVACIDAD</p>
          <a target="_blank" rel="noreferrer" href='https://tokenchampions.com/privacy.pdf' className='contBtnFstSectF'>
          <div className='btnStSect'>Saber más</div>
            <div className='imgCircleArrow'></div>
          </a>
        </div>
      </section>
      <section className='secSectFoot'>
        <div className='contSocialMedia'>
          <a className='linkSocialMedia' href='https://www.facebook.com/Tokenchampions' ><div className='socialMediaIcon'></div></a>
          <a className='linkSocialMedia' href='https://twitter.com/TokenChampions' ><div className='socialMediaIcon1'></div></a>
          <a className='linkSocialMedia' href='https://www.instagram.com/tokenchampions/' ><div className='socialMediaIcon2'></div></a>
          <a className='linkSocialMedia' href='https://www.youtube.com/@tokenchampions' ><div className='socialMediaIcon3'></div></a>
        </div>
      </section>
      <section className='thiSectFoot'>
        <p className='txtTitleContacts'>CONTACTOS DE CONSULTA Y AYUDA</p>
        <div className='contInfo'>
          <div className='contLeftF'>
            <div className='contLeftFInt'>
              <div className='contImgCircleMess'></div>
            </div>
            <div className='contRightFInt'>
              <p className='txtSubTContact'>INFORMACIÓN GENERAL</p>
              <p className='txtSubTContact'>info@tokenchampions.com</p>
              <br></br>
              <p className='txtSubTContact'>AYUDA Y SOPORTE</p>
              <p className='txtSubTContact'>soporte@tokenchampions.com</p>
            </div>
          </div>
          <div className='contRightF'>
            <div className='contLeftFInt'>
              <div className='contImgCircleMess2'></div>
            </div>
            <div className='contRightFInt2'>
              <p className='txtSubTContact'>+185842299145</p>
            </div>
          </div>
        </div>
      </section>
      <section className='fourthSect'>
        <p className='txtFinalFooter'>© 2020 Tokenchampions SCSp - Luxemburgo | Diseñado por Iconic - Miami, USA | Desarrollo Web por Skorus Solutions, Inc - Canada
         | Powered by Xherstone - México <br></br> info@tokenchampions.com | Rue Robert Stümper, 16, L-2557 Luxemburgo, Gran Ducado de Luxemburgo
         | +18584299145</p>
      </section>
    </div>
  )
}
