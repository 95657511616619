/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Footer from '../../layout/Footer/Footer';
import Contact from '../Contact/Contact';

export default function ContactS() {

  return (
    <div className='contContact'>
      <Contact></Contact>
      <Footer />
    </div>
  )
}